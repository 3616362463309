// Libs
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'bootstrap';
import 'popper.js';

// App
import Main from './main';

const main = new Main();
main.init();

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
  event.preventDefault();
  $(this).ekkoLightbox({ wrapping: false });
});
